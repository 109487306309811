import {ChannelTypes} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';
import type {ChannelRecord} from '~/records/ChannelRecord';
import ChannelStore from '~/stores/ChannelStore';

type State = Readonly<{
	selectedChannelIds: Readonly<Record<string, string>>;
	selectedChannelId: string | null;
}>;

const initialState: State = {
	selectedChannelIds: {},
	selectedChannelId: null,
};

class SelectedChannelStore extends PersistedStore<State> {
	constructor() {
		super(initialState, 'SelectedChannelStore', 1);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CHANNEL_SELECT':
				return this.handleChannelSelect(action);
			case 'CHANNEL_DESELECT':
				return this.handleChannelDeselect();
			default:
				return false;
		}
	}

	getSelectedChannel(guildId: string): string {
		return this.state.selectedChannelIds[guildId] ?? this.findDefaultChannel(guildId);
	}

	useSelectedChannel(guildId: string): string {
		const {selectedChannelIds} = this.useStore();
		const selectedChannelId = selectedChannelIds[guildId];

		if (selectedChannelId) {
			return selectedChannelId;
		}

		return this.findDefaultChannel(guildId);
	}

	private findDefaultChannel(guildId: string): string {
		const channels = ChannelStore.getGuildChannels(guildId);
		return this.findFirstTextChannel(channels)?.id ?? '';
	}

	private findFirstTextChannel(channels: ReadonlyArray<ChannelRecord>): ChannelRecord | undefined {
		return channels.find((channel) => channel.type === ChannelTypes.GUILD_TEXT);
	}

	private handleChannelSelect({
		guildId,
		channelId,
	}: Readonly<{
		guildId: string;
		channelId: string;
	}>): boolean {
		if (!guildId || !channelId) {
			return false;
		}

		this.setState((prevState) => ({
			selectedChannelIds: {
				...prevState.selectedChannelIds,
				[guildId]: channelId,
			},
			selectedChannelId: channelId,
		}));

		return true;
	}

	private handleChannelDeselect(): boolean {
		this.setState((prevState) => {
			if (prevState.selectedChannelId == null) {
				return prevState;
			}

			return {
				...prevState,
				selectedChannelId: null,
			};
		});

		return true;
	}

	isChannelSelected(channelId: string): boolean {
		return this.state.selectedChannelId === channelId;
	}

	hasSelectedChannel(guildId: string): boolean {
		return guildId in this.state.selectedChannelIds;
	}

	getCurrentSelectedChannelId(): string | null {
		return this.state.selectedChannelId;
	}

	clearGuildSelection(guildId: string): void {
		this.setState((prevState) => {
			const {[guildId]: _, ...remainingSelections} = prevState.selectedChannelIds;

			return {
				selectedChannelIds: remainingSelections,
				selectedChannelId:
					prevState.selectedChannelId === prevState.selectedChannelIds[guildId] ? null : prevState.selectedChannelId,
			};
		});
	}
}

export default new SelectedChannelStore();
