export type GuildRole = Readonly<{
	id: string;
	name: string;
	color: number;
	position: number;
	permissions: string;
	flags: number;
}>;

export class GuildRoleRecord {
	readonly id: string;
	readonly guildId: string;
	readonly name: string;
	readonly color: number;
	readonly position: number;
	readonly permissions: bigint;
	readonly flags: number;

	constructor(guildId: string, guildRole: GuildRole) {
		this.id = guildRole.id;
		this.guildId = guildId;
		this.name = guildRole.name;
		this.color = guildRole.color;
		this.position = guildRole.position;
		this.permissions = BigInt(guildRole.permissions);
		this.flags = guildRole.flags;
	}

	withUpdates(updates: Partial<GuildRole>): GuildRoleRecord {
		return new GuildRoleRecord(this.guildId, {
			id: this.id,
			name: updates.name ?? this.name,
			color: updates.color ?? this.color,
			position: updates.position ?? this.position,
			permissions: updates.permissions ?? this.permissions.toString(),
			flags: updates.flags ?? this.flags,
		});
	}

	get isEveryone(): boolean {
		return this.id === this.guildId;
	}

	equals(other: GuildRoleRecord): boolean {
		return (
			this.id === other.id &&
			this.guildId === other.guildId &&
			this.name === other.name &&
			this.color === other.color &&
			this.position === other.position &&
			this.permissions === other.permissions &&
			this.flags === other.flags
		);
	}

	toJSON(): GuildRole {
		return {
			id: this.id,
			name: this.name,
			color: this.color,
			position: this.position,
			permissions: this.permissions.toString(),
			flags: this.flags,
		};
	}
}
