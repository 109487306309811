import {ME} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type Channel, ChannelRecord} from '~/records/ChannelRecord';
import type {GuildReadyData} from '~/records/GuildRecord';
import type {Message} from '~/records/MessageRecord';
import SelectedChannelStore from '~/stores/SelectedChannelStore';
import * as RouterUtils from '~/utils/RouterUtils';

type State = Readonly<{
	channels: Readonly<Record<string, ChannelRecord>>;
}>;

const initialState: State = {
	channels: {},
};

class ChannelStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
				return this.handleGuildCreate(action.guild);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'CHANNEL_CREATE':
			case 'CHANNEL_UPDATE':
				return this.handleChannelCreate(action);
			case 'CHANNEL_POSITIONS_UPDATE':
				return this.handleChannelPositionsUpdate(action);
			case 'CHANNEL_PINS_UPDATE':
				return this.handleChannelPinsUpdate(action);
			case 'CHANNEL_DELETE':
				return this.handleChannelDelete(action);
			case 'MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			default:
				return false;
		}
	}

	getChannel(channelId: string): ChannelRecord | undefined {
		return this.state.channels[channelId];
	}

	getChannels(): ReadonlyArray<ChannelRecord> {
		return Object.values(this.state.channels);
	}

	getGuildChannels(guildId: string): ReadonlyArray<ChannelRecord> {
		return Object.values(this.state.channels)
			.filter((channel) => channel.guildId === guildId)
			.sort((a, b) => a.position - b.position);
	}

	useGuildChannels(guildId: string): ReadonlyArray<ChannelRecord> {
		const {channels} = this.useStore();
		return Object.values(channels)
			.filter((channel) => channel.guildId === guildId)
			.sort((a, b) => a.position - b.position);
	}

	useChannel(channelId: string): ChannelRecord | null {
		const {channels} = this.useStore();
		return channels[channelId] ?? null;
	}

	private handleConnectionOpen({guilds}: {guilds: ReadonlyArray<GuildReadyData>}): boolean {
		this.setState(initialState);

		const availableGuilds = guilds.filter((guild) => !guild.unavailable);

		if (availableGuilds.length > 0) {
			this.setState(() => ({
				channels: availableGuilds.reduce<Record<string, ChannelRecord>>(
					(acc, guild) => ({
						// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
						...acc,
						...guild.channels.reduce(
							(channelAcc, channel) => ({
								// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
								...channelAcc,
								[channel.id]: new ChannelRecord(channel),
							}),
							{},
						),
					}),
					{},
				),
			}));
		}

		return true;
	}

	private handleGuildCreate(guild: GuildReadyData): boolean {
		if (guild.unavailable) {
			return false;
		}

		this.setState((prevState) => ({
			channels: {
				...prevState.channels,
				...guild.channels.reduce<Record<string, ChannelRecord>>(
					(acc, channel) => ({
						// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
						...acc,
						[channel.id]: new ChannelRecord(channel),
					}),
					{},
				),
			},
		}));

		return true;
	}

	private handleGuildDelete({guildId}: {guildId: string}): boolean {
		this.setState((prevState) => ({
			channels: Object.fromEntries(
				Object.entries(prevState.channels).filter(([_, channel]) => channel.guildId !== guildId),
			),
		}));
		return true;
	}

	private handleChannelCreate({channel}: {channel: Channel}): boolean {
		this.setState((prevState) => ({
			channels: {
				...prevState.channels,
				[channel.id]: new ChannelRecord(channel),
			},
		}));
		return true;
	}

	private handleChannelPositionsUpdate({
		guildId,
		positions,
	}: {guildId: string; positions: ReadonlyArray<string>}): boolean {
		this.setState((prevState) => {
			const updatedChannels = Object.fromEntries(
				Object.entries(prevState.channels).map(([id, channel]) => {
					if (channel.guildId === guildId) {
						const position = positions.indexOf(id);
						if (position !== -1) {
							return [
								id,
								new ChannelRecord({
									...channel.toJSON(),
									position,
								}),
							];
						}
					}
					return [id, channel];
				}),
			);

			return {channels: updatedChannels};
		});
		return true;
	}

	private handleChannelPinsUpdate({channelId, lastPinAt}: {channelId: string; lastPinAt: number}): boolean {
		this.setState((prevState) => {
			const channel = prevState.channels[channelId];
			if (!channel) {
				return prevState;
			}

			return {
				channels: {
					...prevState.channels,
					[channelId]: new ChannelRecord({
						...channel.toJSON(),
						last_pin_at: lastPinAt,
					}),
				},
			};
		});
		return true;
	}

	private handleChannelDelete({channel}: {channel: Channel}): boolean {
		this.setState((prevState) => {
			const {[channel.id]: _, ...remainingChannels} = prevState.channels;
			return {channels: remainingChannels};
		});

		const guildId = channel.guild_id ?? ME;
		if (SelectedChannelStore.getSelectedChannel(guildId) === channel.id) {
			RouterUtils.transitionTo(`/channels/${guildId}`);
		}

		return true;
	}

	private handleMessageCreate({message}: {message: Message}): boolean {
		this.setState((prevState) => {
			const channel = prevState.channels[message.channel_id];
			if (!channel) {
				return prevState;
			}

			return {
				channels: {
					...prevState.channels,
					[message.channel_id]: new ChannelRecord({
						...channel.toJSON(),
						last_message_id: message.id,
					}),
				},
			};
		});
		return true;
	}
}

export default new ChannelStore();
