import type {Channel} from '~/records/ChannelRecord';
import type {GuildEmoji} from '~/records/GuildEmojiRecord';
import type {GuildMember} from '~/records/GuildMemberRecord';
import type {GuildRole} from '~/records/GuildRoleRecord';
import {GuildRoleRecord} from '~/records/GuildRoleRecord';
import type {Presence} from '~/stores/PresenceStore';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';

export type Guild = Readonly<{
	id: string;
	name: string;
	description: string | null;
	icon: string | null;
	vanity_url_code: string | null;
	owner_id: string;
	system_channel_id: string | null;
	flags: number;
	unavailable?: boolean;
}>;

export type GuildReadyData = Readonly<{
	id: string;
	properties: Omit<Guild, 'roles'>;
	channels: ReadonlyArray<Channel>;
	emojis: ReadonlyArray<GuildEmoji>;
	members: ReadonlyArray<GuildMember>;
	presences: ReadonlyArray<Presence>;
	roles: ReadonlyArray<GuildRole>;
	unavailable?: boolean;
}>;

type GuildInput = Guild | GuildRecord;

export class GuildRecord {
	readonly id: string;
	readonly name: string;
	readonly description: string | null;
	readonly icon: string | null;
	readonly flags: number;
	readonly vanityURLCode: string | null;
	readonly ownerId: string;
	readonly systemChannelId: string | null;
	readonly roles: Readonly<Record<string, GuildRoleRecord>>;
	readonly unavailable?: boolean;

	constructor(guild: GuildInput) {
		this.id = guild.id;
		this.name = guild.name;
		this.description = guild.description;
		this.icon = guild.icon;
		this.flags = guild.flags;
		this.vanityURLCode = this.normalizeVanityUrlCode(guild);
		this.ownerId = this.normalizeOwnerId(guild);
		this.systemChannelId = this.normalizeSystemChannelId(guild);
		this.roles = this.normalizeRoles(guild);
		this.unavailable = guild.unavailable;
	}

	private normalizeVanityUrlCode(guild: GuildInput): string | null {
		return this.isGuildInput(guild) ? guild.vanity_url_code : guild.vanityURLCode;
	}

	private normalizeOwnerId(guild: GuildInput): string {
		return this.isGuildInput(guild) ? guild.owner_id : guild.ownerId;
	}

	private normalizeSystemChannelId(guild: GuildInput): string | null {
		return this.isGuildInput(guild) ? guild.system_channel_id : guild.systemChannelId;
	}

	private normalizeRoles(guild: GuildInput): Readonly<Record<string, GuildRoleRecord>> {
		return Object.freeze('roles' in guild ? {...guild.roles} : {});
	}

	private isGuildInput(guild: GuildInput): guild is Guild {
		return 'vanity_url_code' in guild;
	}

	static fromGuildReadyData(guildData: GuildReadyData): GuildRecord {
		const roles = Object.freeze(
			guildData.roles.reduce<Record<string, GuildRoleRecord>>(
				(acc, role) => ({
					// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
					...acc,
					[role.id]: new GuildRoleRecord(guildData.properties.id, role),
				}),
				{},
			),
		);

		return new GuildRecord({
			...guildData.properties,
			roles,
			unavailable: guildData.unavailable,
		});
	}

	toJSON(): Guild {
		return {
			id: this.id,
			name: this.name,
			description: this.description,
			icon: this.icon,
			flags: this.flags,
			vanity_url_code: this.vanityURLCode,
			owner_id: this.ownerId,
			system_channel_id: this.systemChannelId,
			unavailable: this.unavailable,
		};
	}

	withUpdates(guild: Partial<Guild>): GuildRecord {
		return new GuildRecord({
			...this,
			name: guild.name ?? this.name,
			description: guild.description ?? this.description,
			icon: guild.icon ?? this.icon,
			flags: guild.flags ?? this.flags,
			vanityURLCode: guild.vanity_url_code ?? this.vanityURLCode,
			ownerId: guild.owner_id ?? this.ownerId,
			systemChannelId: guild.system_channel_id ?? this.systemChannelId,
			unavailable: guild.unavailable ?? this.unavailable,
		});
	}

	withRoles(roles: Record<string, GuildRoleRecord>): GuildRecord {
		return new GuildRecord({
			...this,
			roles: Object.freeze({...roles}),
		});
	}

	addRole(role: GuildRoleRecord): GuildRecord {
		return this.withRoles({
			...this.roles,
			[role.id]: role,
		});
	}

	removeRole(roleId: string): GuildRecord {
		const {[roleId]: _, ...remainingRoles} = this.roles;
		return this.withRoles(remainingRoles);
	}

	updateRole(role: GuildRoleRecord): GuildRecord {
		if (!this.roles[role.id]) {
			return this;
		}
		return this.addRole(role);
	}

	getRole(roleId: string): GuildRoleRecord | undefined {
		return this.roles[roleId];
	}

	get createdAt(): number {
		return SnowflakeUtils.extractTimestamp(this.id);
	}

	isOwner(userId: string): boolean {
		return this.ownerId === userId;
	}

	equals(other: GuildRecord): boolean {
		if (!(other instanceof GuildRecord)) {
			return false;
		}

		return (
			this.id === other.id &&
			this.name === other.name &&
			this.description === other.description &&
			this.icon === other.icon &&
			this.flags === other.flags &&
			this.vanityURLCode === other.vanityURLCode &&
			this.ownerId === other.ownerId &&
			this.systemChannelId === other.systemChannelId &&
			this.unavailable === other.unavailable &&
			Object.keys(this.roles).length === Object.keys(other.roles).length &&
			Object.entries(this.roles).every(([id, role]) => role.equals(other.roles[id]))
		);
	}
}
