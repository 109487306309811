import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

type State = Readonly<{
	lastSelectedGuildId: string | null;
	selectedGuildId: string | null;
}>;

const initialState: State = {
	lastSelectedGuildId: null,
	selectedGuildId: null,
};

class SelectedGuildStore extends PersistedStore<State> {
	constructor() {
		super(initialState, 'SelectedGuildStore', 1);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'GUILD_SELECT':
				return this.handleGuildSelect(action);
			case 'GUILD_DESELECT':
				return this.handleGuildDeselect();
			default:
				return false;
		}
	}

	getLastSelectedGuildId(): string | null {
		return this.state.lastSelectedGuildId;
	}

	getSelectedGuildId(): string | null {
		return this.state.selectedGuildId;
	}

	useSelectedGuildId(): string | null {
		const {selectedGuildId} = this.useStore();
		return selectedGuildId;
	}

	useLastSelectedGuildId(): string | null {
		const {lastSelectedGuildId} = this.useStore();
		return lastSelectedGuildId;
	}

	private handleGuildSelect({guildId}: Readonly<{guildId: string}>): boolean {
		if (!guildId) {
			return false;
		}

		if (guildId === this.state.selectedGuildId) {
			return false;
		}

		this.setState((prevState) => ({
			lastSelectedGuildId: prevState.selectedGuildId,
			selectedGuildId: guildId,
		}));

		return true;
	}

	private handleGuildDeselect(): boolean {
		if (this.state.selectedGuildId == null) {
			return false;
		}

		this.setState((prevState) => ({
			lastSelectedGuildId: prevState.selectedGuildId,
			selectedGuildId: null,
		}));

		return true;
	}
}

export default new SelectedGuildStore();
