import type {ChannelOverwriteType, ChannelType} from '~/Constants';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';

export type ChannelOverwrite = Readonly<{
	id: string;
	type: ChannelOverwriteType;
	allow: string;
	deny: string;
}>;

export class ChannelOverwriteRecord {
	readonly id: string;
	readonly type: ChannelOverwriteType;
	readonly allow: bigint;
	readonly deny: bigint;

	constructor(overwrite: ChannelOverwrite) {
		this.id = overwrite.id;
		this.type = overwrite.type;
		this.allow = BigInt(overwrite.allow);
		this.deny = BigInt(overwrite.deny);
	}

	withUpdates(overwrite: Partial<ChannelOverwrite>): ChannelOverwriteRecord {
		return new ChannelOverwriteRecord({
			id: this.id,
			type: overwrite.type ?? this.type,
			allow: overwrite.allow ?? this.allow.toString(),
			deny: overwrite.deny ?? this.deny.toString(),
		});
	}

	equals(other: ChannelOverwriteRecord): boolean {
		return this.id === other.id && this.type === other.type && this.allow === other.allow && this.deny === other.deny;
	}

	toJSON(): ChannelOverwrite {
		return {
			id: this.id,
			type: this.type,
			allow: this.allow.toString(),
			deny: this.deny.toString(),
		};
	}
}

export type Channel = Readonly<{
	id: string;
	guild_id: string;
	name: string;
	topic: string | null;
	url: string | null;
	type: ChannelType;
	position: number;
	flags: number;
	last_message_id: string | null;
	last_pin_at: number | null;
	overwrites?: ReadonlyArray<ChannelOverwrite>;
}>;

export class ChannelRecord {
	readonly id: string;
	readonly guildId: string;
	readonly name: string;
	readonly topic: string | null;
	readonly url: string | null;
	readonly type: ChannelType;
	readonly position: number;
	readonly flags: number;
	readonly lastMessageId: string | null;
	readonly lastPinAt: number | null;
	readonly overwrites: Readonly<Record<string, ChannelOverwriteRecord>>;

	constructor(channel: Channel) {
		this.id = channel.id;
		this.guildId = channel.guild_id;
		this.name = channel.name;
		this.topic = channel.topic;
		this.url = channel.url;
		this.type = channel.type;
		this.position = channel.position;
		this.flags = channel.flags;
		this.lastMessageId = channel.last_message_id;
		this.lastPinAt = channel.last_pin_at;
		this.overwrites =
			channel.overwrites?.reduce(
				(acc, overwrite) => ({
					// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
					...acc,
					[overwrite.id]: new ChannelOverwriteRecord(overwrite),
				}),
				{},
			) ?? {};
	}

	withUpdates(updates: Partial<Channel>): ChannelRecord {
		return new ChannelRecord({
			id: this.id,
			guild_id: updates.guild_id ?? this.guildId,
			name: updates.name ?? this.name,
			topic: updates.topic ?? this.topic,
			url: updates.url ?? this.url,
			type: updates.type ?? this.type,
			position: updates.position ?? this.position,
			flags: updates.flags ?? this.flags,
			last_message_id: updates.last_message_id ?? this.lastMessageId,
			last_pin_at: updates.last_pin_at ?? this.lastPinAt,
			overwrites: updates.overwrites ?? Object.values(this.overwrites).map((o) => o.toJSON()),
		});
	}

	withOverwrite(overwrite: ChannelOverwriteRecord): ChannelRecord {
		return new ChannelRecord({
			...this.toJSON(),
			overwrites: Object.values({
				...this.overwrites,
				[overwrite.id]: overwrite,
			}).map((o) => o.toJSON()),
		});
	}

	equals(other: ChannelRecord): boolean {
		return JSON.stringify(this) === JSON.stringify(other);
	}

	get createdAt(): number {
		return SnowflakeUtils.extractTimestamp(this.id);
	}

	toJSON(): Channel {
		return {
			id: this.id,
			guild_id: this.guildId,
			name: this.name,
			topic: this.topic,
			url: this.url,
			type: this.type,
			position: this.position,
			flags: this.flags,
			last_message_id: this.lastMessageId,
			last_pin_at: this.lastPinAt,
			overwrites: Object.values(this.overwrites).map((o) => o.toJSON()),
		};
	}
}
