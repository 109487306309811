import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type Guild, type GuildReadyData, GuildRecord} from '~/records/GuildRecord';
import {type GuildRole, GuildRoleRecord} from '~/records/GuildRoleRecord';
import SelectedGuildStore from '~/stores/SelectedGuildStore';
import * as RouterUtils from '~/utils/RouterUtils';

type State = {
	guilds: Record<string, GuildRecord>;
};

const initialState: State = {
	guilds: {},
};

class GuildStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action) {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
				return this.handleGuildCreate(action.guild);
			case 'GUILD_UPDATE':
				return this.handleGuildUpdate(action.guild);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'GUILD_ROLE_CREATE':
				return this.handleGuildRoleCreate(action);
			case 'GUILD_ROLE_DELETE':
				return this.handleGuildRoleDelete(action);
			case 'GUILD_ROLE_UPDATE':
				return this.handleGuildRoleUpdate(action);
			default:
				return false;
		}
	}

	getGuild(guildId: string): GuildRecord | undefined {
		return this.state.guilds[guildId];
	}

	getGuildRoles(guildId: string, includeEveryone = false): Array<GuildRoleRecord> {
		const guild = this.state.guilds[guildId];
		if (!guild) {
			return [];
		}
		return Object.values(guild.roles).filter((role) => includeEveryone || role.id !== guildId);
	}

	getGuilds(): Array<GuildRecord> {
		return Object.values(this.state.guilds);
	}

	useGuild(guildId: string): GuildRecord | undefined {
		const {guilds} = this.useStore();
		return guilds[guildId];
	}

	private handleConnectionOpen({guilds}: {guilds: Array<GuildReadyData>}): boolean {
		const availableGuilds = guilds.filter((guild) => !guild.unavailable);

		if (availableGuilds.length === 0) {
			this.setState(initialState);
			return true;
		}

		this.setState(() => ({
			guilds: availableGuilds.reduce<Record<string, GuildRecord>>((acc, guildData) => {
				acc[guildData.id] = GuildRecord.fromGuildReadyData(guildData);
				return acc;
			}, {}),
		}));

		return true;
	}

	private handleGuildCreate(guild: GuildReadyData): boolean {
		if (guild.unavailable) {
			return false;
		}

		this.setState((prevState) => ({
			guilds: {
				...prevState.guilds,
				[guild.id]: GuildRecord.fromGuildReadyData(guild),
			},
		}));

		return true;
	}

	private handleGuildUpdate(guild: Guild): boolean {
		this.setState((prevState) => {
			const existingGuild = prevState.guilds[guild.id];
			if (!existingGuild) {
				return prevState;
			}

			const updatedGuild = new GuildRecord({
				...guild,
				roles: existingGuild.roles,
			});

			return {
				guilds: {
					...prevState.guilds,
					[guild.id]: updatedGuild,
				},
			};
		});

		return true;
	}

	private handleGuildDelete({guildId, unavailable}: {guildId: string; unavailable?: boolean}): boolean {
		this.setState((prevState) => ({
			guilds: Object.fromEntries(Object.entries(prevState.guilds).filter(([id]) => id !== guildId)),
		}));

		if (!unavailable && SelectedGuildStore.getSelectedGuildId() === guildId) {
			RouterUtils.transitionTo('/');
		}

		return true;
	}

	private updateGuildWithRoles(
		prevState: State,
		guildId: string,
		roleUpdater: (roles: Record<string, GuildRoleRecord>) => Record<string, GuildRoleRecord>,
	): State {
		const guild = prevState.guilds[guildId];
		if (!guild) {
			return prevState;
		}

		const updatedRoles = roleUpdater({...guild.roles});
		const updatedGuild = new GuildRecord({
			...guild.toJSON(),
			roles: updatedRoles,
		});

		return {
			guilds: {
				...prevState.guilds,
				[guildId]: updatedGuild,
			},
		};
	}

	private handleGuildRoleCreate({guildId, role}: {guildId: string; role: GuildRole}): boolean {
		this.setState((prevState) =>
			this.updateGuildWithRoles(prevState, guildId, (roles) => ({
				...roles,
				[role.id]: new GuildRoleRecord(guildId, role),
			})),
		);
		return true;
	}

	private handleGuildRoleDelete({guildId, roleId}: {guildId: string; roleId: string}): boolean {
		this.setState((prevState) =>
			this.updateGuildWithRoles(prevState, guildId, (roles) =>
				Object.fromEntries(Object.entries(roles).filter(([id]) => id !== roleId)),
			),
		);
		return true;
	}

	private handleGuildRoleUpdate({guildId, role}: {guildId: string; role: GuildRole}): boolean {
		this.setState((prevState) =>
			this.updateGuildWithRoles(prevState, guildId, (roles) => ({
				...roles,
				[role.id]: new GuildRoleRecord(guildId, role),
			})),
		);
		return true;
	}
}

export default new GuildStore();
